<template>
  <button
    class="grid grid-cols-[auto_1fr] grid-rows-[auto_auto] rounded-2xl bg-[#ECBA33]/10 px-5 py-4"
  >
    <img
      class="row-span-2 h-12 translate-y-1 rotate-[-75deg] scale-125"
      src="/images/dorito.png"
      alt=""
    />
    <div class="larken pl-2 text-left text-xl text-[#ECBA33]">Join Country Club</div>
    <div class="pl-6 text-left text-sm text-[#ECBA33]/50">Buy $DORITO</div>
  </button>
</template>
